
import Navbar from "./Layout/Navbar";
import Footer from "./Layout/Footer";
const Thank = () => {
  return (
    <div>
      <Navbar />
<div className="container Thank_section">
    <div className="row">
        <div className="col-12">
           <center> <img src="/Asstes/Images/imgpsh_fullsize_anim (1).gif" alt='images' /></center>
            <h1>Thank You for Reaching Out</h1>
        </div>
    </div>
</div>
      <Footer />
    </div>
  )
}
export default Thank;