import { getBaseUrl } from "../utils";
import axios from "axios";
import Layout from "./Layout/Layout";
import Navbar from "./Layout/Navbar";
import Footer from "./Layout/Footer";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
const BlogSectionDeatials = () => {
  const [otherblog, setotherblog] = useState([]);
  const [singleblog, setsingleblog] = useState([]);
  console.log("otherblog---->", otherblog);
  function getblogData() {
    axios
      .post(getBaseUrl() + "user_api/blogData", {
        cat_id: "",
      })
      .then((res) => {
        console.log("res---->", res.data);
        if (res.data.status)
          setotherblog(res.data.results);
      });
  }
  const navigate = useNavigate();
  const location = useLocation();
  if (!location) {
    navigate("/");
  }
  function blogPage(blogData) {
    console.log("blogData---onclick------->", blogData)
    setsingleblog(blogData);
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    getblogData();
    window.scrollTo(0, 0);
    const { blogData } = location.state ?? "";
    setsingleblog(blogData);
  }, [location]);
  return (
    <div>
      <Navbar />
      <div className="Blog_section_section">
        <div className="container">
          <div className="row">
            <div className="col-12 Text_blog_section">
              <h6>Type of Psychologist </h6>
              <h1>
                {singleblog.name}
              </h1>
              <h6>{singleblog.Created_date}</h6>
            </div>
          </div>
        </div>
      </div>
      <div className="Blog_section_text_section">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 text_blog_section">
              <div dangerouslySetInnerHTML={{ __html: singleblog.description }} />
            </div>
          </div>
        </div>
      </div>
   

      <div className="BLog_section_main">
        <div className="container Blog_section_all">
          <div className="Blog_other">
            <h1>Other Blogs </h1>
          </div>
          <div className="row">
            {otherblog.map((eachData) => {
              return (
                <>
                  <div className="col-lg-4 Blog_section_text_main">
                    <div className="Blog_images_section_all">
                      <div className="Blog_images_section_text">
                        <img src={eachData.image} alt="images" />
                      </div>

                      <h6>{eachData.name}</h6>
                      <p>
                        <div dangerouslySetInnerHTML={{ __html: eachData.description.slice(0, 100) + '...' }} />
                      </p>

                      <button onClick={() => blogPage(eachData)} >Read More</button>
                    </div>
                  </div>
                </>
              );
            })}
           
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default BlogSectionDeatials;
