import { getBaseUrl } from "../../utils";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import 'animate.css';
import "./Layout.css";
const Footer = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [setting, setsetting] = useState({});
    const [emailAddresses, setemailAddresses] = useState([]);

    console.log("-setting-->", setting);
    console.log("-setting-setting.facebook_link->", setting.facebook_link);


    function getsettingData() {
        axios
            .get(getBaseUrl() + "user_api/setting")
            .then((res) => {
                console.log("res--setting-->", res.data);
                if (res.data.status)
                    var email = res.data.results.contact_gmail;
                if (email != "") {
                    var myArray = email.split(',');
                    setemailAddresses(myArray)
                }
                setsetting(res.data.results);
            });
    }

    const [OpenLogin, setOpenLogin] = useState(false);
    const OepnHeandle = () => {
        setOpenLogin(true);
    };
    const CloseHeandle = () => {
        setOpenLogin(false);
    };
    const menus = [
        {
            label: "Home",
            href: "/",
        },
        {
            label: "About",
            href: "#About",
        },
        {
            label: "Gallery",
            href: "#Gallery",
        },

        {
            label: "Blogs",
            href: "BlogSection",
        },
        {
            label: "Contact us",
            href: "#Footer",
        },

    ];
    const [affix, setAffix] = useState(false);

    const handleScroll = () => {
        if (window.scrollY > 50) {
            setAffix(true);
            //  console.log("OK");
        } else {
            setAffix(false);
        }
    };

    function scrollToGallery() {
        var pathname = location.pathname;
        if (pathname == '/') {
            window.scrollTo({ top: 1800, behavior: 'smooth' });
        } else {
            navigate('/', {})
            const timer = setTimeout(() => {
                window.scrollTo({ top: 1800, behavior: 'smooth' });
            }, 2000);
        }
    }

    function scrollToabout() {
        var pathname = location.pathname;
        if (pathname == '/') {
            window.scrollTo({ top: 1100, behavior: 'smooth' });
        } else {
            navigate('/', {})
            const timer = setTimeout(() => {
                window.scrollTo({ top: 1100, behavior: 'smooth' });
            }, 2000);
        }
    }
    function scrollToBottom() {
        var pathname = location.pathname;
        if (pathname == '/') {
            window.scrollTo({ top: document.documentElement.scrollHeight, behavior: 'smooth' });
        } else {
            navigate('/', {})
            const timer = setTimeout(() => {
                window.scrollTo({ top: document.documentElement.scrollHeight, behavior: 'smooth' });
            }, 2000);
        }
    }
    useEffect(() => {
        getsettingData();
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <>
            <footer className="Footer_section_main" id="Footer">
                <div class="container">
                    <div class="footer-content pt-5 pb-5">
                        <div class="row">
                            <div class="col-xl-7 col-lg-7 mb-50">
                                <div class="footer-widget">
                                    <div class="footer-logo">
                                        <a href="index.html"><img src="/Asstes/Images/Annant_Education_Logo.png" class="img-fluid" alt="logo" /></a>
                                    </div>
                                    <div class="footer-text">
                                        <h1>Contact us today to start your journey with professional support
                                        </h1>
                                    </div>
                                    <div class="footer-social-icon">
                                        <input type="text" name="text" placeholder="Enter your email" className="Search_input" />
                                        <button className="Submit_btn" onClick={() => navigate('/BookAppoinment', {})}  >Book Appointment </button>
                                        {/* <Link to="/BookAppoinment"> <button className="Submit_btn" >Book Appointment </button> </Link> */}
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-2 col-lg-2 col-md-6 mb-30">
                                <div class="footer-widget">
                                    {/* <ul>
                                        <li><a href="#">Home</a></li>
                                        <li><a href="#">About us </a></li>
                                        <li><a href="#">Our Team</a></li>
                                        <li><a href="#">Blogs</a></li>
                                        <li><a href="#">Testimonial</a></li>

                                    </ul> */}
                                    <ul >
                                        <li onClick={() => { scrollToabout() }} ><Link to="" >About</Link></li>
                                        <li onClick={() => { scrollToGallery() }}><Link to="" >Gallery</Link></li>
                                        <li><Link to="/BlogSection">Blogs</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-3 col-md-6 mb-50">
                                <div class="footer-widget">
                                    <ul>
                                        <li><a href="">Contact us :  {setting.contact_number}</a></li>
                                        {/* <li><a href={`mailto:${setting.contact_gmail}`}>{setting.contact_gmail}</a></li> */}
                                        {emailAddresses.map((email, index) => (
                                            <li key={index}>
                                                <a href={`mailto:${email}`}>{email}</a>
                                            </li>
                                        ))}
                                        <a href={setting.facebook_link}> <img src="/Asstes/Images/f_logo_RGB-Blue_512 1.png" /></a>
                                        <a href={setting.instagram_link}> <img src="/Asstes/Images/Group 1.png" /></a>
                                        <a href={setting.whatsapp_link}><img src="/Asstes/Images/Icon.png" /></a>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="copyright-area">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-6 col-lg-6 text-lg-left">
                                <div class="copyright-text">
                                    <p>© 2024 Annant Edu. All rights reserved.</p>
                                    <div class="footer-menu d-lg-none">
                                        <ul>
                                            <li> <Link to="/Terms-of-Service">Terms of Service</Link></li>
                                            <li><Link to="/Privacy-policy">Privacy Policy</Link></li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                            <div class="col-xl-6 col-lg-6 d-none d-lg-block">
                                <div class="footer-menu">
                                    <ul>
                                        <li> <Link to="/Terms-of-Service">Terms of Service</Link></li>
                                        <li><Link to="/Privacy-policy">Privacy Policy</Link></li>
                                        {/* <li><a href="#">Cookies</a></li> */}

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
};
export default Footer;