import { getBaseUrl } from "../utils";
import axios from "axios";
import Navbar from "./Layout/Navbar";
import Footer from "./Layout/Footer";
// import Layout from "./Layout/Layout";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

const Privacypolicy = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [setting, setsetting] = useState({});
  function getsettingData() {
    axios
      .get(getBaseUrl() + "user_api/setting")
      .then((res) => {
        console.log("res--setting-->", res.data);
        if (res.data.status)
          setsetting(res.data.results);
      });
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    getsettingData();
  }, [location]);

  return (
    <div>
      <Navbar />
      <div className="Term_section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <section id="terms-of-service" >
                <div className="card">
                  {/* <h1 className="primary-heading">Terms of Service</h1> */}
                  <p className="paragraph"  style={{ padding: 10 }}>
                  <div dangerouslySetInnerHTML={{ __html: setting.privacy_policy }} />

                  </p>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
export default Privacypolicy;