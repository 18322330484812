import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import 'animate.css';
import "./Layout.css";
const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [OpenLogin, setOpenLogin] = useState(false);
  const OepnHeandle = () => {
    setOpenLogin(true);
  };
  const CloseHeandle = () => {
    setOpenLogin(false);
  };
  const menus = [
    // {
    //   label: "Home",
    //   href: "/",
    // },
    // {
    //   label: "About",
    //   href: "#About",
    // },
    // {
    //   label: "Gallery",
    //   href: "#Gallery",
    // },

    // {
    //   label: "Blogs",
    //   href: "BlogSection",
    // },
    // {
    //   label: "Contact us",
    //   href: "#Footer",
    // },

  ];
  const [affix, setAffix] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setAffix(true);
      //  console.log("OK");
    } else {
      setAffix(false);
    }
  };

  function scrollToGallery() {
    var pathname = location.pathname;
    if (pathname == '/') {
      window.scrollTo({ top: 1800, behavior: 'smooth' });
    } else {
      navigate('/', {})
      const timer = setTimeout(() => {
        window.scrollTo({ top: 1800, behavior: 'smooth' });
      }, 2000);
    }
  }

  function scrollToabout() {
    var pathname = location.pathname;
    if (pathname == '/') {
      window.scrollTo({ top: 1100, behavior: 'smooth' });
    } else {
      navigate('/', {})
      const timer = setTimeout(() => {
        window.scrollTo({ top: 1100, behavior: 'smooth' });
      }, 2000);
    }
  }
  function scrollToBottom() {
    var pathname = location.pathname;
    if (pathname == '/') {
      window.scrollTo({ top: document.documentElement.scrollHeight, behavior: 'smooth' });
    } else {
      navigate('/', {})
      const timer = setTimeout(() => {
        window.scrollTo({ top: document.documentElement.scrollHeight, behavior: 'smooth' });
      }, 2000);
    }
  }
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <>
      <nav className={`navbar ${affix ? 'affix' : ''}`}>
        <div className="navbar-container container">
          <input type="checkbox" name="" id="" />
          <div className="hamburger-lines">
            <span className="line line1" />
            <span className="line line2" />
            <span className="line line3" />
          </div>
          <ul className="menu-items">
            {menus.map((item, index) => (
              // console.log("href",item)

              <li key={index}>
                {item.routerLink ? (
                  <Link to={item.routerLink}>{item.label}</Link>
                ) : (
                  // <a href={item.href}>{item.label}</a>
                  <Link to={item.href}>{item.label}</Link>
                )}
              </li>
            ))}
            <li  ><Link to="/" >Home</Link></li>
            <li onClick={() => { scrollToabout() }} ><Link to="" >About</Link></li>
            <li onClick={() => { scrollToGallery() }}><Link to="" >Gallery</Link></li>
            <li><Link to="/BlogSection">Blogs</Link></li>
            <li onClick={() => { scrollToBottom() }}><Link to="" >Contact us</Link></li>

            <Link to="/BookAppoinment">
              <button className="Login_section_button">Book Appointment</button>
            </Link>
          </ul>
          <img src="/Asstes/Images/Annant_Education_Logo.png" className="logo" style={{ width: '150px' }} />
        </div>
      </nav>


      {
        OpenLogin &&
        <div className="lOGIN_section_pages animate__animated animate__zoomIn">
          <i class="ri-close-line" onClick={CloseHeandle}></i>
          <h4>Form Login</h4>
          <button style={{ background: '#D4B26B', color: 'white' }}>Login</button>
          <button onClick={OepnHeandle}>Sign up</button>
          <form>
            <div>
              <input type="email" name="email" placeholder="Email" />
            </div>
            <div>
              <input type="text" name="Password" placeholder="Password" />
            </div>
            <button className="Login_button">Login</button>
            <p>Not a member? <a href='3'>Singup now</a></p>
          </form>
        </div>
      }
    </>
  );
};
export default Navbar;
