import { getBaseUrl } from "../utils";
import axios from "axios";
import Navbar from "./Layout/Navbar";
import Footer from "./Layout/Footer";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
const BookEvent = () => {
  const [user_name, setuser_name] = useState("");
  const [user_number, setuser_number] = useState("");
  const [date, setdate] = useState("");
  const [user_comment, setuser_comment] = useState("");
  const [cat_id, setcat_id] = useState("");
  const [catblog, setcatblog] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();
  // const state = location.state;
  // const { ID } = state;
  //console.log("ID--B------->", ID);
  // if(ID){
  //   setcat_id(ID);
  // }


  function getblogcatData() {
    axios
      .get(getBaseUrl() + "user_api/CategoryService")
      .then((res) => {
        console.log("res---->", res.data);
        if (res.data.status)
          setcatblog(res.data.results);
      });
  }

  function handalSubmit() {
    // console.log("user_name------>",user_name);
    if (!user_name) {
      alert("Name field is required");
      return false;
    }
    if (!user_number) {
      alert("Number field is required");
      return false;
    }
    if (!user_comment) {
      alert("Message field is required");
      return false;
    }
    axios
      .post(getBaseUrl() + "user_api/booking", {
        booking_type: "Event",
        user_name: user_name,
        user_number: user_number,
        date: "",
        user_comment: user_comment,
        cat_id: cat_id,
      })
      .then((res) => {
        console.log("res---->", res.data);
        if (res.data.status)
          // alert(res.data.message);
        navigate('/Thank', { state: { } });
        setuser_name("");
        setuser_number("");
        setdate("");
        setuser_comment("");
        window.scrollTo(0, 0);
      });
  }

  useEffect(() => {
  //  getblogcatData();
    window.scrollTo(0, 0);
     const { ID } = location.state ?? "";
     setcat_id(ID);
  }, [location]);

  return (
    <div>
      <Navbar />
      <div className="Book_appoinents_sectyion_main">
        <div className="container">
          <div className="row">
            <div className="col-12 Book_appoinent_section" style={{ padding: '0', margin: '0' }}>
              <div>
                <img src="/Asstes/Images/Group 22.png" />
              </div>
              <div className="form_section_main">
                <h1>BOOK Event</h1>
                {/* <form> */}
                <div className="Form_section">
                  <div>


                    <input type="text" name="name" placeholder="Full Name" style={{ background: 'red;' }} onChange={(e) => { setuser_name(e.target.value) }} value={user_name} /><br></br>
                    <input type="number" name="name" placeholder="Contact No." className="Number" onChange={(e) => { setuser_number(e.target.value) }} value={user_number} /><br></br>
                    {/* <div className="Input_section_date">
                      <input type="text" name="" placeholder="DD/MM/YYYY" className="Date_section" onChange={(e) => { setdate(e.target.value) }} value={date} />
                      <img src="/Asstes/Images/Trailing Icon Button.png" className="Date_img" />
                    </div> */}
                    <input type="text" name="message" placeholder="Message" className="Meassges" onChange={(e) => { setuser_comment(e.target.value) }} value={user_comment} />
                    <button onClick={() => { handalSubmit() }} >BOOK Event</button>
                  </div>
                </div>
                {/* </form> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
export default BookEvent;