import axios from "axios";
import React, { Fragment, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "animate.css";
import "react-alice-carousel/lib/alice-carousel.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "swiper/css";
import Navbar from "./Navbar";
import Footer from "./Footer";
import "aos/dist/aos.css";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { getBaseUrl } from "../../utils";
import YouTubeModal from './YouTubeModal';

const Home = () => {

  const [videoId, setVideoId] = useState('dQw4w9WgXcQ'); // Default video ID
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModalWithVideo = (newVideoId) => {
    setVideoId(newVideoId);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };


  const navigate = useNavigate();
  function blogPage(blogData) {
    navigate('/BlogSectionDeatials', { state: { blogData: blogData } });
  };

  function clickOnServiceCat(ID) {
    console.log("serviceID----home------>", ID);
    navigate('/BookAppoinment', { state: { ID: ID } });
  }

  const [serviceData, setserviceData] = useState([]);
  const [latestEvent, setlatestEvent] = useState([]);
  const [faq, setfaq] = useState([]);
  const [blog, setblog] = useState([]);



  console.log("serviceData---->", serviceData);
  console.log("latestEvent---->", latestEvent);
  console.log("faq---->", faq);



  function gethomeData() {
    axios
      .get(getBaseUrl() + "user_api/homeData")
      .then((res) => {
        console.log("res---->", res.data);
        if (res.data.status)
          setserviceData(res.data.CategoryService);
        setlatestEvent(res.data.latestEvent);
        setfaq(res.data.faq);
        setblog(res.data.latestBlog);
      });
  }

  const responsive = {
    0: { items: 1 },
    768: { items: 1 },
    1024: { items: 1 },
  };


  const convertTo12Hour = (time24) => {
  let [hours, minutes] = time24.split(':');
  hours = parseInt(hours);

  const period = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12 || 12; // Convert '0' hour to '12'

  return `${hours}:${minutes} ${period}`;
};

  useEffect(() => {
    gethomeData();
    window.scrollTo(0, 0);
  }, []);

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  return (
    <div>
      <Navbar />
      <div className="Home_banner_section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 Home_content_section">
              <h1>
                Empowering you to find peace and clarity in <br></br>
                <span style={{ color: "#AF864E" }}>your life's journey.</span>
              </h1>
              <p>
                Welcome to Annant Education Counselling: A Safe Space for
                Healing. Experienced Therapists Ready to Support You. Our goal
                is to establish a friendly, safe environment where you may
                freely express your emotions.
              </p>
              <div className="Button_section_home">
                <Link to="/BookAppoinment"><button className="Book_Appoinment">Book appointment</button></Link>
                <button className="Book_Know">know more</button>
              </div>
            </div>
            <div className="col-lg-6 Home_Images_section">
              <img src="/Asstes/Images/Home.png" />
            </div>
          </div>
        </div>
      </div>


      <div className="Collage_section_main">
        <div className="container Collages_section">
          <div className="row">
            {serviceData.map((eachData) => {
              return (
                <>
                  <div className="col-lg-3 Cloages_Parent_section">
                    <div className="Child_section">
                      <center>
                        <div onClick={() => clickOnServiceCat(eachData._id)}>
                          {/* <img src="/Asstes/Images/college 1.svg" /> */}
                          <img src={eachData.image} />
                        </div>
                        <h5>
                          {eachData.name}
                        </h5>
                      </center>
                    </div>
                  </div>
                </>
              );
            })}
            {/* <div className="col-lg-3 Cloages_Parent_section">
                <div className="Child_section">
                  <center>
                    <div>
                      <img src="/Asstes/Images/mentorship 1.svg" />
                    </div>
                    <h5>
                      Career counselling<br></br>& Guidance
                    </h5>
                  </center>
                </div>
              </div>
              <div className="col-lg-3 Cloages_Parent_section">
                <div className="Child_section">
                  <center>
                    <div>
                      <img
                        src="/Asstes/Images/relationship-counselling.svg"
                        style={{
                          width: "60px",
                          height: "60px",
                          marginTop: "20px",
                        }}
                      />
                    </div>
                    <h5 style={{ paddingTop: "30px" }}>
                      Relationship<br></br>
                      counselling
                    </h5>
                  </center>
                </div>
              </div>
              <div
                className="col-lg-3 Cloages_Parent_section"
                style={{ paddingRight: "0" }}
              >
                <div className="Child_section">
                  <center>
                    <div>
                      <img
                        src="/Asstes/Images/marriage-counselling (1) 1.png"
                        style={{
                          width: "60px",
                          height: "60px",
                          marginTop: "20px",
                        }}
                      />
                    </div>
                    <h5 style={{ paddingTop: "30px" }}>
                      Marriage<br></br>
                      counselling
                    </h5>
                  </center>
                </div>
              </div> */}
          </div>
        </div>
      </div>

      <div className="About_section_main_main" id="About">
      <div className="About">
      <div className="container About_section-main">
          <div className="row">
            <div className="col-lg-4 Parent_section">
              <h2>ABOUT US </h2>
              <h5>
                At Annant Education, we believe <br></br>in guiding you
                towards a <br></br>
                <span style={{ color: "#D4B26B" }}> brighter future. </span>
              </h5>
              <p>
              Annant Education is one of the <b>Best consulting services company in connaught place</b>  neighborhood, having over 18 years of experience in the field of counseling. We have been offering top-notch advisory services to the education industry. As a preferred organization, we have agreed to take on consulting tasks.
              </p>
            </div>
            <div className="col-lg-4 Parent_section">
              <img src="/Asstes/Images/About.png" alt="images" />
            </div>
            <div className="col-lg-4 Parent_section">
              <h2>Why Choose us</h2>
              <h5>
                Expert Guidance Our experienced counsellor provide
                personalized advice to help you achieve your{" "}
                <span style={{ color: "#D4B26B" }}>educational goals.</span>
              </h5>
              <p>Our team of knowledgeable counselors is dedicated to providing compassionate and knowledgeable support. We are here to help you overcome the challenges of life because we are well-versed in a variety of mental health-related topics. Since each person is unique, we tailor our strategy to meet your particular needs. Together, you and your counselor will build a unique approach that advances your objectives and fosters personal development.</p>
            </div>
          </div>
        </div>
      </div>
      </div>
      <div className="No1_Career_Cansulting_section">
        <div className="container No1_Career_Cansulting_section_Main_section">
          <div className="row">
            <div className="col-3 No1_Career_Cansulting_section_Main_section_child_section">
              <center>
                <img src="/Asstes/Images/Group 78.svg" alt="images" />
                <div>
                <h1>No.1</h1>
                <p>Career consulting platform in India</p>
                </div>
              </center>
            </div>
            <div className="col-3 No1_Career_Cansulting_section_Main_section_child_section">
              <center>
                <img src="/Asstes/Images/Group 78.svg" alt="images" />
                <div>
                  <h1>50+</h1>
                  <p> Consultant</p>
                </div>
              </center>
            </div>
            <div className="col-3 No1_Career_Cansulting_section_Main_section_child_section">
              <center>
                <img src="/Asstes/Images/Group 78.svg" alt="images" />
                <div>
                <h1>50000+ </h1>
                <p>Satisfied clients</p>
                </div>
              </center>
            </div>
            <div className="col-3 No1_Career_Cansulting_section_Main_section_child_section">
              <center>
                <img src="/Asstes/Images/Group 78.svg" alt="images" />
                <div>
                  <h1>96% </h1>
                  <p>Success rate</p>
                </div>
              </center>
            </div>
          </div>
        </div>
      </div>
      <div className="No1_Career_Cansulting_section_mobile_section">
        <div className="row">
          <div className="col-12 Mobile_section_counsoling">
            <div className="Images_section_mobile">
              <img src="/Asstes/Images/Group 78.svg" alt="images" />
            </div>
            <div className="TExt_section_mobile_view">
              <h1>No.1</h1>
              <p>Career consulting platform in India</p>
            </div>
          </div>
          <div className="col-12 Mobile_section_counsoling">
            <div className="Images_section_mobile">
              <img src="/Asstes/Images/Group 78.svg" alt="images" />
            </div>
            <div className="TExt_section_mobile_view">
              <h1>50+</h1>
              <p> Consultant</p>
            </div>
          </div>
          <div className="col-12 Mobile_section_counsoling">
            <div className="Images_section_mobile">
              <img src="/Asstes/Images/Group 78.svg" alt="images" />
            </div>
            <div className="TExt_section_mobile_view">
              <h1>50000+ </h1>
              <p>Satisfied clients</p>
            </div>
          </div>
          <div className="col-12 Mobile_section_counsoling">
            <div className="Images_section_mobile">
              <img src="/Asstes/Images/Group 78.svg" alt="images" />
            </div>
            <div className="TExt_section_mobile_view">
              <h1>96% </h1>
              <p>Success rate</p>
            </div>
          </div>
        </div>
      </div>
      <div className="Gallery_section_main" id="Gallery">
        <div className="Gellery_Sectound_section">
          <div className="container Gallery_section_container">
            <h1>Gallery</h1>
            <div className="row">
              <div className="col-lg-3 Gallery_section_2">
                <div className="Gallery_images_section">
                  <img src="/Asstes/Images/Gellory (4).png" />
                </div>
              </div>
              <div className="col-lg-4 Gallery_section_4">
                <div className="Gallery_section_images">
                  <img src="/Asstes/Images/Gellory (3).png" />
                </div>
              </div>
              <div className="col-lg-5 Gallery_section_4">
                <div className="Gallery_section_images">
                  <img src="/Asstes/Images/Gellory (2).png" />
                </div>
              </div>
              <div className="col-lg-4 Gallery_secound_section">
                <div className="row Gallery_images_section_secound">
                  <div className="col-12 Gellory_secound_all_imasges">
                    <img src="/Asstes/Images/Rectangle 163843.png" />
                  </div>
                  <div className="col-12 Gellory_secound_all_imasges">
                    <img src="/Asstes/Images/Rectangle 163840.png" />
                  </div>
                </div>
              </div>
              <div className="col-lg-8 Gallery_secound_images_section">
                <div className="Secound_images_section_right">
                  <img src="/Asstes/Images/Gellory (1).png" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="Team_secing_main">
        <div className="TEam_section_secound">
          <div className="container Team_section_all">
            <div className="Events_section">
              <h1>Upcoming Events </h1>
            </div>
            <div className="row Events_row">
              <AliceCarousel
                autoPlayInterval={4000}
                autoPlay={true}
                fadeOutAnimation={true}
                mouseTrackingEnabled={true}
                disableDotsControls
                disableButtonsControls
                infinite
                disableAutoPlayOnAction={true}
              >
                {latestEvent.map((eachData) => {
                  return (
                    <>
                      <div className="col-12 Images_Event_section">
                        <div className="row">
                          <div className="col-lg-3 Images">
                            {" "}
                            <img src={eachData.image} alt="images" />
                            {/* <img src="/Asstes/Images/Gellory (2).png" alt="images" /> */}
                          </div>
                          <div className="col-lg-9 TExt_section_main">
                            <h4>{eachData.name}</h4>
                            <h6>Location :- {eachData.location}</h6>
                            <h6>Date :- {eachData.date}</h6>
                            <h6>Time :- {convertTo12Hour(eachData.time)} </h6>
                            <p>
                              <div dangerouslySetInnerHTML={{ __html: eachData.description }} />
                            </p>
                           <div className="Button_Event">                           

                           
                           <button onClick={() => openModalWithVideo('anotherVideoId')}>
                              Watch  Video
                            </button>
                            <button className="button_sec" onClick={() =>  navigate('/BookEvent', { state: { } })}>Book Now</button>
                            {/* <Link to="/BookEvent"></Link>  */}
                           </div>

                            {/* Modal */}
                            <YouTubeModal
                              videoId={eachData.youtube_id}
                              modalIsOpen={modalIsOpen}
                              closeModal={closeModal}
                            />

                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </AliceCarousel>
            </div>
          </div>
        </div>
      </div>

      <div className="Testimonial_section_main" id="EVENT">
        <div className="Testmonial_section_secound">
          <div className="container Testimonial_section_main_main_container">
            <h1>
              Getting Started is Extremely{" "}
              <span style={{ color: "#D4B26B" }}>Simple & Easy</span>
            </h1>
            <div className="row">
              <div className="col-lg-4 Extremely_Simple_section_main">
                <center>
                  {" "}
                  <img src="/Asstes/Images/booking (2) 1.svg" alt="images" />
                </center>
                <div className="Text_Testimonial_main">
                  <h4>Step 1 : Schedule a Call </h4>
                  <p>
                    Schedule your discovery session with our team of business
                    consultants and ensure your presence during the call
                  </p>
                </div>
              </div>
              <div className="col-lg-4 Extremely_Simple_section_main">
                <center>
                  {" "}
                  <img
                    src="/Asstes/Images/meeting.svg"
                    alt="images"
                    style={{ height: "90px" }}
                  />
                </center>
                <div className="Text_Testimonial_main">
                  <h4>Step 2 : Pinpoint the obstacles</h4>
                  <p>
                    pinpoint the obstacles hindering your Career progress and
                    develop a strategic plan to overcome them.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 Extremely_Simple_section_main">
                <center>
                  {" "}
                  <img
                    src="/Asstes/Images/grow.svg"
                    alt="images"
                    style={{ height: "90px" }}
                  />
                </center>
                <div className="Text_Testimonial_main">
                  <h4>Step 3 : Grow your Career</h4>
                  <p>
                    Grow your Career with a reliable partners who possesses a
                    optimistic mindset and good - natured sense of humor.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="Qustion_section_main">
        <div className="Qustion_secound_section">
          <div className="container Qustion_section_container">
            <div className="row">
              <h1>
                Common Question{" "}
                <span style={{ color: "#AF864E" }}>Answered</span>
              </h1>
              <div className="col-lg-6 Qustion_images_section">
                <img src="/Asstes/Images/faq 1 (1).png" />
              </div>
              <div className="col-lg-6 Qustion_section_text">
                {faq.map((item, index) => (
                  <div className="Main_qustion_section" key={index}>
                    <div
                      className="Qustion"
                      onClick={() => toggleAccordion(index)}
                    >
                      <h6><div dangerouslySetInnerHTML={{ __html: item.question }} /></h6>
                      <i
                        className={`ri-arrow-${activeIndex === index ? "up" : "down"
                          }-s-line`}
                      ></i>
                    </div>
                    {activeIndex === index && (
                      <div className="Answer_section">
                        <p><div dangerouslySetInnerHTML={{ __html: item.ans }} /></p>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="Blog_section_main">
        <div className="container Blog_section">
          <div className="row">
            <div className="Blog_text_section">
              <h1>
                Guidance and Growth: Discover More on Our<br></br> counselling{" "}
                <span style={{ color: "#D4B26B" }}>Blogs</span>
              </h1>
            </div>
            {blog.map((eachData) => {
              return (
                <>

                  <div className="col-lg-4 Blog_section_maion_div">
                    <div className="Blog_section_text">
                      <div className="Text_blog">
                        <img src={eachData.image} />
                      </div>
                      <h6>{eachData.name}</h6>
                      <p>
                        <div dangerouslySetInnerHTML={{ __html: eachData.description.slice(0, 100) + '...' }} />

                      </p>
                      {/* <Link to="/BlogSectionDeatials"> */}
                      <button onClick={() => blogPage(eachData)} >Read More</button>
                      {/* </Link> */}
                    </div>
                  </div>
                </>
              );
            })}
            {/* <div className="col-lg-4 Blog_section_maion_div">
              <div className="Blog_section_text">
                <div className="Text_blog">
                  <img src="/Asstes/Images/Rectangle 163851.png" />
                </div>
                <h6>Transformative Experience!</h6>
                <p>
                  Although I was first apprehensive to begin therapy, the
                  support and direction this counselling service ....
                </p>
                <Link to="/BlogSectionDeatials">
                  <button>Read More</button>
                </Link>
              </div>
            </div>
            <div className="col-lg-4 Blog_section_maion_div">
              <div className="Blog_section_text">
                <div className="Text_blog">
                  <img src="/Asstes/Images/Rectangle 163851 (2).png" />
                </div>
                <h6>Professional and Compassionate</h6>
                <p>
                  These counselors are extraordinarily skilled and kind. They
                  take the time to pay attention to you and .....
                </p>
                <Link to="/BlogSectionDeatials">
                  <button>Read More</button>
                </Link>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Home;
