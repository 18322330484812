import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'remixicon/fonts/remixicon.css'
import Navbar from "./components/Layout/Navbar";
import Footer from "./components/Layout/Footer";
import Home from "./components/Layout/Home";
import BookAppoinment from "./components/BookAppoinments";
import BookEvent from "./components/BookEvent";
import BlogSectionDeatials from "./components/BlogSection_Deatials";
import Blogsection from "./components/BlogSection"
import Term from "./components/Term";
import Privacypolicy from "./components/Privacypolicy";
import Thank from "./components/ThankPages";

import Booking from "./components/Booking";
function App() {
  return (
    <div className="">
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={ <Home />}> </Route>
          <Route path="BookAppoinment" element={ <BookAppoinment />}></Route>
          <Route path="BookEvent" element={ <BookEvent />}></Route>
          <Route path="Navbar" element={ <Navbar />}></Route>
          <Route path="Footer" element={ <Footer />}></Route>
          <Route path="BlogSectionDeatials" element={ <BlogSectionDeatials />}></Route>
          <Route path="Blogsection" element={ <Blogsection />}></Route>
          <Route path="Terms-of-Service" element={ <Term />}></Route>
          <Route path="Privacy-policy" element={ <Privacypolicy />}></Route>
          <Route path="Booking" element={ <Booking />}></Route>
          <Route path="Thank" element={ <Thank />}></Route>
          </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;