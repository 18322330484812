import Layout from "./Layout/Layout"
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect } from 'react';
const Booking= () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    
    return(
        <div>
            <Layout>
                <div className="Book_appoinents_sectyion_main">
                <div className="container">
                    <div className="row">
                      <div className="col-12 Book_appoinent_section"style={{padding:'0',margin:'0'}}>
                        <div>
                            <img src="/Asstes/Images/Group 22.png" />
                        </div>
                        <div className="form_section_main">
                            <h1>BOOK EVENT</h1>
                            <form>
                               <div className="Form_section">
                               <div>
                                    <input type="text" name="name" placeholder="Full Name" style={{background:'red;'}}></input><br></br>
                                    <input type="number" name="name" placeholder="Contact No." className="Number"></input><br></br>
                                    <input type="text" name="message" placeholder="Message"className="Meassges" />
                                    <button>BOOK Now</button>
                                </div>
                               </div>
                            </form>
                        </div>
                      </div>
                    </div>
                </div>
                </div>
            </Layout>
        </div>
    )
}
export default  Booking;