import { getBaseUrl } from "../utils";
import axios from "axios";
import Navbar from "./Layout/Navbar";
import Footer from "./Layout/Footer";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
const Blogsection = () => {
  const navigate = useNavigate();
  function blogPage(blogData) {
    navigate('/BlogSectionDeatials', { state: { blogData: blogData } });
  };
  const [otherblog, setotherblog] = useState([]);
  const [catblog, setcatblog] = useState([]);
  const [catID, setcatID] = useState("");
  const [search, setsearch] = useState("");

  function getblogData(cat_id,search) {
    console.log("catID--------getblogData------------>",catID);
    axios
      .post(getBaseUrl() + "user_api/blogData",{
        cat_id: cat_id,
        search:search,
      })
      .then((res) => {
        console.log("res---->", res.data);
        if (res.data.status)
          setotherblog(res.data.results);
      });
  }
  function getblogcatData() {
    axios
      .get(getBaseUrl() + "user_api/CategoryBlog")
      .then((res) => {
        console.log("res---->", res.data);
        if (res.data.status)
          setcatblog(res.data.results);
      });
  }
   function setcatfilter(cat_id){
    console.log("cat_id---->", cat_id);
    setcatID(cat_id);
     getblogData(cat_id);
  }
  function getsearch(data){
    console.log("search---->", data);
    setsearch(data);
     getblogData(catID,search);
  }
  useEffect(() => {
    getblogData(catID,search);
    getblogcatData();
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Navbar />
        <div className="Bolg_section_deatials">
          <div className="container">
            <div className="row">
              <div className="col-7">
                <h6>
                  Home / <span style={{ color: "#D4B26B" }}>Blog</span>
                </h6>
              </div>
              <div className="col-5">
                <img src="/Asstes/Images/Group 22.png" />
              </div>
            </div>
          </div>
        </div>
        <div className="Blog_search_filuter">
          <div className="container">
            <div className="row">
              <div className="col-8 Search_section">
                <input type="text" name="text" placeholder="search"  onKeyUp={(e)=>{ getsearch(e.target.value)}} />
              </div>
                <div className="col-4 Choose_pages_section"style={{paddingRight:'0'}}>
      <div className="custom-select-container">
        <select
          className="custom-select"
          onChange={(e) => setcatfilter(e.target.value)}
        >
          {catblog.map((eachData) => (
            <option key={eachData._id} value={eachData._id}>
              {eachData.name}
            </option>
          ))}
        </select>
        <i className="ri-arrow-down-s-line custom-arrow"></i>
      </div>
    </div>
            </div>
          </div>
        </div>
        <div className="BLog_section_main">
          <div className="container Blog_section_all">
            <div className="row">
            {otherblog.map((eachData) => {
              return (
                <>
              <div className="col-lg-4 Blog_section_text_main">
            <div className="blog">
                  <div className="Blog_images_section_all">
                  <div className="Blog_images_section_text">
                    <img src={eachData.image} alt="images"/>
                  </div>

                  <h6>{eachData.name}</h6>
                  <p>
                  <div dangerouslySetInnerHTML={{ __html: eachData.description.slice(0, 100)+'...' }} />
                  </p>
                  <button onClick={() => blogPage(eachData)} >Read More</button>

                </div>
            </div>
              </div>
              </>
              );
            })}

            </div>
          </div>
        </div>
      <Footer />
    </div>
  );
};
export default Blogsection;
